export type Configuration = {
  header: {
    title: string;
    subtitle: string;
    description: string;
    logo: string;
    isThereMore: string;
    visible: boolean;
  };
  footer: {
    visible: boolean;
  };
  citation: {
    sharepoint_directory: string;
    parent_directory: string;
  };
  sharepoint: {
    information_link: string;
  };
  tooltip: {
    tooltip_info: string;
    sample_prompt: string;
  };
  toggle: {
    visible: boolean;
  };
  prompts: {
    visible: boolean;
    topics: {
      title: string;
      description: string;
      userPrompt: string;
    }[];
  };
  info: {
    visible: boolean;
  };
};

// HEADER
// Title, Subtitle: These are the same for all apps at this point.
// Descritpion: not currently in use but it might be useful to keep as we could use it in the future.
// Logo: this is customized for each app. The Project Lead is supposed to provide this.
// Is there more: this is the same for all apps at this point.
// Location: frontend\src\pages\chat\Chat.tsx


// CITATION
// Sharepoint Directory: this is the first part of link to the folder where the data is stored.
// Parent Directory: this is the second part of the link to the folder where the data is stored.
// We pull these out of the URL in Sharepoint.
// Examples:
// In Madison A-M
// https://osfiles.sharepoint.com/sites/AI--DataIndexing/Madison%20AN/Forms/AllItems.aspx?id=%2Fsites%2FAI--DataIndexing%2FMadison%20AN%2FWashoe%20County%20-%20NV%20-%20BCC
// parent_directory: "&parent=/sites/AI--DataIndexing/Madison%20AN/"
// Note: the above example looks like they are in A-N, but this was becuase the directory was incorrectly named when it was first created. The URL has "AN" but the actual directory is "A-M".
// In Madison N-Z
// https://osfiles.sharepoint.com/sites/AI--DataIndexing/Madison%20NZ/Forms/AllItems.aspx?id=%2Fsites%2FAI--DataIndexing%2FMadison%20NZ%2FWashoe%20County%20-%20NV%20-%20BCC
// parent_directory: "&parent=/sites/AI--DataIndexing/Madison%20NZ/"
// Location: frontend\src\pages\chat\Chat.tsx


// SHAREPOINT
// This is a link to the Data folder for the client. We create these in SP and they should be set to view only, no edit or download permmissions.
// Locations: frontend\src\pages\layout\Content.tsx, frontend\src\pages\chat\Content.tsx

// TOOLTIP
// tooltip_info: This is the tooltip on the landing page showing what information is in the index. The project lead should provide this.
// sample_prompt: This is only used inthe Staff report versions. The project lead should provide this.
// Location: frontend\src\pages\chat\Content.tsx

export const configuration: Configuration = {
  header: {
    title: "What information is in your index?",
    subtitle: "What information is in your index?",
    description:
      "This research assistant includes Board meeting minutes and packets from Jan 2016 - May 2024.",
    logo: "https://54493.fs1.hubspotusercontent-na1.net/hubfs/54493/iGovern/icons/city-of-reno-logo.svg",
    visible: true,
    isThereMore: "Is there any more information on this topic?"
  },
  citation: {
    sharepoint_directory: "https://osfiles.sharepoint.com/sites/AI--DataIndexing/Madison%20NZ/Forms/AllItems.aspx?id=%2Fsites%2FAI--DataIndexing%2FMadison%20NZ%2FReno%20-%20NV%20-%20City%20Council%20-%20Scraped",
    parent_directory: "&parent=/sites/AI--DataIndexing/Madison%20NZ/"
  },
  sharepoint: {
    information_link: "https://osfiles.sharepoint.com/:f:/s/AI--DataIndexing/ErIDMGKXywFBiphi9hBBoYcB-LRtUP7pYJ-zC0Svv-SMjA?e=5iY0kr"
  },
  tooltip: {
    tooltip_info: "Minutes, Staff Reports, Statutes, Master Plans from 2016-present. Updated monthly.",
    sample_prompt: ""
  },
  footer: {
    visible: true,
  },
  toggle: {
    visible: false,
  },
  prompts: {
    visible: true,
    topics: [
      {
        title: "Authorities & Duties",
        description:
          "Outlines operational responsibilities, board composition and terms and references County Code and NRS.",
        userPrompt:
          "What are the authorities and duties of the {topic}? Cite the specific County Code Chapter and NRS in your response. Additionally, explain the composition of the board, board terms and any other important information about the scope of this commission. Use section headers in your response for easy reading. Include date and title of most recently uploaded document.",
      }
    ],
  },

  info: {
    visible: false,
  },
};
