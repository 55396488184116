let count = 0; // Declare the variable 'count'
let savedPrompt = '';
export function isThisLoading(isLoading: boolean) {
    // console.log('isLoading: ', isLoading, ` test ` + `${count}`)
    count++;
    let promptCount = count
    if (count >= 1 && count <= 7) {
        autoPrompts(promptCount, savedPrompt);
    } else if (count == 8) {
        generateReport(promptCount, savedPrompt);
    }

}

const updatePromptTextArea = (textContent: any) => {
    console.log('f: ', count, ' text:', textContent)
    const promptTextArea = document.getElementById('promptTextArea') as HTMLTextAreaElement;
    if (promptTextArea) {
        promptTextArea.value = '';
        promptTextArea.value = textContent; // Include inputText value
    }
}

const triggerPromptButtonClick = (event?: Event) => {
    // Programmatically trigger button click
    const promptEnterButton = document.getElementById('promptEnter') as HTMLButtonElement | null;
    if (promptEnterButton) {
        promptEnterButton.click();
        if (event) {
            event.stopPropagation();
        }
    }
}

export function firstPrompt() {
    console.log('f: ', count);

    const loadingImage = document.getElementById('loadingImage') as HTMLElement;
    loadingImage.style.display = 'block';

    const textArea = document.getElementById('staffReportTextArea') as HTMLTextAreaElement;
    
    savedPrompt = textArea.value;
    // SUMMARY
    const textContent = `Write a property or project summary of the ${savedPrompt} Include location, description of the request and reason for the request, and  reference the land use designation it falls within. Include why the application is required. Include the recommendation from the Planning Commission. Include one sentence about the key issues to be addressed to comply with the request. Indicate primary concerns identified in this request. Write at least one paragraph and up to three. `;

    // Usage with delay:
    setTimeout(() => {
        updatePromptTextArea(textContent);
    }, 50); // 50-millisecond delay

    // Usage with delay:
    setTimeout(() => {
        triggerPromptButtonClick();
        const questionInputContainer = document.querySelector('#chatInputWrapper');
        if (questionInputContainer) {
            questionInputContainer.classList.add('active');
        }

    }, 250); // 50-millisecond delay

}

export function autoPrompts(promptCount: number, mySavedPrompt: any) {
    // console.log('f: ', count);
    let textContent = '';

    const textArea = document.getElementById('staffReportTextArea') as HTMLTextAreaElement;

    // PREVIOUS ACTION
    if (promptCount == 1) {
        textContent = `Research all past city council actions taken on the ${mySavedPrompt} and outline them in paragraph format. Include specific actions and dates. If there has been no previous action taken by the City Council, respond with "There is no recent Council action relevant to this item." `;
    }
    // BACKGROUND
    else if (promptCount == 2) {
        textContent = `Summarize the Planning Commission staff report related to this ${mySavedPrompt}. Include reference to the Master Plan and historical information. Reference any actions taken by the Planning Commission and City Council. `
    }
    // DISCUSSSION
    else if (promptCount == 3) {
        textContent = `Reference Planning Commission meeting minutes related to the ${mySavedPrompt} in the first sentence. Include date of the meeting. Summarize presentations and public comments related to the ${mySavedPrompt} from the meeting minutes. Summarize the Planning Commission discussions related to the ${mySavedPrompt} in bullet points. `
    }
    // FINANCIAL IMPLICATIONS
    else if (promptCount == 4) {
        textContent = `Outline any financial implications related to the ${mySavedPrompt}. If there aren't any, use "None at this time." In the response. `;
    }
    // Recommendation
    else if (promptCount == 5) {
        textContent = `Use "The Planning Commission recommends council..." Approve or deny based on information above.`;
    }
    // Proposed Motion
    else if (promptCount == 6) {
        textContent = `Write a possible motion about the {topic}. Use this exact structure: "I move to…". Use ALL the language in the recommendation from the previous response.`;
    }
    // Proposed Motion
    else if (promptCount == 7) {
        textContent = `Write an opposing motion about the {topic} to the above motion. Use this exact structure: "I move to..." `;
    }
    // SUBJECT
    // else if (promptCount == 6) {
    //     textContent = `Repeat ${mySavedPrompt} exactly and nothing else. No summartization or paraphrasing.`;
    // }

    // console.log(mySavedPrompt)

    // Usage with delay:
    setTimeout(() => {
        updatePromptTextArea(textContent);
    }, 50); // 50-millisecond delay

    // Usage with delay:
    setTimeout(() => {
        triggerPromptButtonClick();
    }, 1000); // 50-millisecond delay

}

export const generateReport = (promptCount: number, mySavedPrompt: any) => {
    const selectedDivs = document.querySelectorAll('.content');
    const contentData: Record<string, string> = {};
    const targetDiv = document.getElementById('chatMessageStream') as HTMLElement;

    const superscriptPattern = /<sup\b[^>]*>([\s\S]*?)<\/sup>/gi;

    function removeHTMLBetweenSuperscript(selectedDivsText: string): string {
        const superscriptPattern = /<sup\b[^>]*>([\s\S]*?)<\/sup>/gi;
        return selectedDivsText.replace(superscriptPattern, '');
    }

    selectedDivs.forEach((div, index) => {
        const selectedDivs = div as HTMLElement;
        const selectedDivsHtml = selectedDivs.innerHTML;
        if (selectedDivsHtml !== null) {
            const cleanedText = removeHTMLBetweenSuperscript(selectedDivsHtml);
            contentData[index] = cleanedText.replaceAll('<p>', '<p style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<td>', '<td style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<li>', '<li style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;"><p style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt; margin: 0;">').replaceAll('</li>', '</p></li>').replaceAll('<strong>', '<strong style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<h1>', '<h1 style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<h2>', '<h2 style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<h3>', '<h3 style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">').replaceAll('<h4>', '<h4 style="font-family: \'Times New Roman\', Times, serif !important; font-size: 12pt; line-height: 14pt;">');
        }
    });

    // Usage with delay:
    setTimeout(() => {
        const StaffReportHTML = `
    <div class="chatMessageGpt">
        <div class="chatMessageId">
            <div class="answerContainer static-class selected" tabindex="0" id="printArea">
                <div class="answerText content">

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Date</strong>:</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To</strong>: Mayor and City Council</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Through</strong>: Doug Thornley, City Manager</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Subject</strong>: ${mySavedPrompt}</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">From</strong>: </p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Department</strong>: </p>

                    <hr />

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Summary:</strong></p>${contentData[0]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Alignment with Strategic Plan:</strong></p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Economic and Community Development  </p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Previous Council Action</strong>:</p>${contentData[1]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Background</strong>:</p>${contentData[2]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Discussion:</strong></p>${contentData[3]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Financial Implications:</strong></p>${contentData[4]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Legal Implications:</strong></p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Legal review completed for compliance with City procedures and Nevada law. </p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><em style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">General Review Criteria and Considerations:</em></strong> The decision-making body shall review all development applications for compliance with the applicable general review criteria stated below. </p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">(1) Consistency with the Reno Master Plan: The proposed development shall be consistent with the Reno Master Plan. The decision-making authority: </p>
                    <ol>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Shall weigh competing plan goals, policies, and strategies; and</li>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">May approve an application that provides a public benefit even if the development is contrary to some of the goals, policies, or strategies in the Reno Master Plan.</li>
                    </ol>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">(2) Compliance with Title 18: The proposed development shall comply with all applicable standards in this Title unless the standard is lawfully modified or varied. Compliance with these standards is applied at the level of detail required for the subject submittal. </p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">(3) Mitigates Traffic Impacts: The project mitigates traffic impacts based on applicable standards of the City of Reno and the Regional Transportation Commission (RTC). </p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">(4) Provides Safe Environment: The project provides a safe environment for pedestrians and people on bicycles. </p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">(5) Rational Phasing Plan. If the application involves phases, each phase of the proposed development contains all of the required streets, utilities, landscaping, open space, and other improvements that are required to serve or otherwise accompany the completed phases of the project and shall not depend on subsequent phases for those improvements. </p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><em style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Master Plan Amendment:</em></strong> To adopt an amendment to the Master Plan Land Use Map, Council shall find that: </p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">(1) The amendment is in substantial conformance with Master Plan priorities and policies; </p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">(2) Activities and development allowed by the proposed land use will be reasonably compatible with nearby land uses; and </p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">(3) Plans are in place to provide public services and facilities in accordance with the Master Plan Concurrency Management System. </p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><em style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Zoning Map Amendment:</em></strong> All applications for zoning map amendments shall meet the approval criteria in Section 18.08.304(e), Approval Criteria Applicable to all Applications, and the following findings: </p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">(1) The amendment, together with changed components of the Title, promotes or does not conflict with the provisions of Nevada Revised Statute (NRS) 278.250(2)(outlined below): </p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">The zoning regulations must be adopted in accordance with the Master Plan for land use and be designed: </p>
                    <ol type="a">
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To preserve the quality of air and water resources;</li>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To promote the conservation of open space and the protection of other natural and scenic resources from unreasonable impairment;</li>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To consider existing views and access to solar resources by studying the height of new buildings which will cast shadows on surrounding residential and commercial developments;</li>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To reduce the consumption of energy by encouraging the use of products and materials that maximize energy efficiency in the construction of buildings;</li>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To provide for recreational needs;</li>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To protect life and property in areas subject to floods, landslides, and other natural disasters;</li>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To conform to the adopted population plan, if required by NRS 278.170;</li>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To develop a timely, orderly, and efficient arrangement of transportation and public facilities and services, including public access and sidewalks for pedestrians, and facilities and services for bicycles;</li>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">i. To ensure that the development on land is commensurate with the character of the physical limitations of the land;</li>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To take into account the immediate and long-range financial impact of the application of particular land to particular kinds of development, and the relative suitability of the land for development;</li>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To promote health and general welfare;</li>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To ensure the development of an adequate supply of housing for the community, including the development of affordable housing;</li>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To ensure the protection of existing neighborhoods and communities, including the protection of rural preservation neighborhoods;</li>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To promote systems that use solar or wind energy;</li>
                        <li style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">To foster the coordination and compatibility of land uses with any military installation in the city, county, or region, taking into account the location, purpose, and stated mission of the military installation.</li>
                    </ol>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">(2) The amendment is in substantial conformance with the Master Plan. </p>

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Recommendation:</strong></p>${contentData[5]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Proposed Motion:</strong></p>${contentData[6]}

                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;"><strong style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Alternative Motion:</strong></p>${contentData[7]}




                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">&nbsp;</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Attachments:</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Case Maps:</p>
                    <p style="font-family: 'Times New Roman', Times, serif !important; font-size: 12pt; line-height: 14pt;">Ordinance:</p>
                </div>
            </div>
            <div class="promptRepsonseInteractionContainer">
                <div class="promptRepsonseInteractionSaveButton" id="downloadAsWord" role="button" aria-label="Save button">Download as Word</div>
            </div>
        </div>
    </div>
    `
        const loadingImage = document.getElementById('loadingImage') as HTMLElement;
        loadingImage.style.display = 'none';
        targetDiv.insertAdjacentHTML('beforeend', StaffReportHTML);

        console.log(contentData, 'Completed!')
    }, 2000); // 50-millisecond delay

}